










































































































































import { Component, Prop, Vue } from "vue-property-decorator"
import Axios from 'axios'
import DniField from '@/components/DniField.vue'
import ReusableButton from '@/components/ReusableButton.vue'
import { Configuration, TitleTemplatePublic, SelectedCountry, RedCapital, Countries } from '@/configuration'
import ReCaptcha from '@/components/ReCaptcha.vue'
import API from '@/api'
import { isRutValid, DniTrim } from '@/utils'
import { EventBus } from '@/main'

@Component({
  metaInfo: {
    title: 'Registrarse',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return {
      showPassword: false,
      RedCapital,
      SelectedCountry,
      terms: false,
      showError: false,
      // type: 2,
      type: [
        {
          id: 2,
          value: 'Invertir',
        },
        {
          id: 3,
          value: 'Solicitar',
        },
      ],
      user: {
        name: '',
        lastname: '',
        email: '',
        password: '',
        c_password: '',
        dni: '',
        phone: '',
        type: 2,
      },
      rules: {
        name: [
          (v: any) => !!v || 'Nombre y Apellido es requerido',
          (v: any) => /^[A-Za-záéíóúÁÉÍÓÚñÑ ]+$/.test(v) || "Ingrese su nombre y apellido", 
          (v: any) => /..+ +../.test(v) || "Ingrese su nombre y apellido",
          (v: any) =>
            v.length <= 255 || "Nombre no puede exceder los 255 caracteres."
        ],
        rut: [
          // @ts-ignore
          (v: any) => !!v || `${RedCapital[SelectedCountry].DniName} es requerido`,
          // @ts-ignore
          (v: any) => /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/.test(v) || `${RedCapital[SelectedCountry].DniName} invalido Ej: 12.345.678-9`
        ],
        ruc: [
          // @ts-ignore
					(v: any) => !!v || `${RedCapital[SelectedCountry].DniName} es requerido`,
					// @ts-ignore
					(v: any) => v.toString().length != 11 || `${RedCapital[SelectedCountry].DniName} no válido`,
        ],
        email: [
          (v: any) => !!v || 'E-mail es requerido',
          (v: any) => /^.+@.+\..+/gi.test(v) || 'El e-mail tiene que ser valido'
        ],
        password: [],
        phone: [
          (v: any) => !(/[^\d]/gim.test(v)) || 'Tiene que ser solo números',
          (v: any) => !!v || 'Teléfono es requerido',
          (v: any) => v.length >= 7 && v.length < 10 || 'Teléfono es inválido',
          
        ],
      },
      dialog: false,
      btn: {
        clickable: false,
        loading: false,
        isOk: null,
        successText: 'Guardado correctamente',
        errorText: 'Error interno',
      },
      errorMessage: [],
      passwordError: null,
    };
  },
  async beforeMount() {
    if (this.$route.query.h != null) {
      // console.log(`${Configuration.api.landing}${this.$route.query.h}`)
      Axios.get(`${Configuration.api.landing}${this.$route.query.h}`).then( (res: any) => {
        let i = res.data[0]
        this.$data.user.name = i.name
        this.$data.user.lastname = i.lastname
        this.$data.user.email = i.email
        this.$data.user.phone = i.phone
      }).catch( (err: any) => {
        console.log(err)
      })
      // https://landing.redcapital.cl/api/leads?hash=

      // console.log('hash '+this.$route.query.h)
      // this.$data.user.name = window.btoa(this.$route.query.h.toString())
    }

    API.getPasswordRules().then((response) => {
      const res = response.data;
      const rules = JSON.parse(res.data);
      Object.values(rules).map((e) => {
        //@ts-ignore
        let rule = {'text': Object.values(e)[0].text, 'regex':Object.values(e)[0].rule}
        this.$data.rules.password = [...this.$data.rules.password, rule];
      });
    }).catch(() => {
      this.$data.rules.password = [];
    })
  },
  watch: {
    terms() {
      // @ts-ignore
      this.validate()
    },
    user: {
      handler() {
        // @ts-ignore
        this.validate()
      },
      deep: true,
    },
  },
  methods: {
    validate() {
      if(this.$data.terms == true) {
        // @ts-ignore
        if (this.$refs.form.validate()) {
          //@ts-ignore
          if (!this.validatePassword(this.$data.user.password)){
            this.$data.passwordError = "Ingrese una contraseña válida"
            this.$data.btn.clickable = false
          } else {
            this.$data.btn.clickable = true
            this.$data.passwordError = null
          }
        } else {
          this.$data.btn.clickable = false
          this.$data.passwordError = null
        }
      }
    },
    termError() {
      this.$data.showError = true
      setTimeout(() => {
        this.$data.showError = false
      }, 2500)
    },
    login() {
      EventBus.$emit('open-login-dialog', this.$route.query.to != null ? `${this.$route.query.to}` : '')
    },
    async onSubmit(response) {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.btn.loading = true
        this.$data.errorMessage = []
        this.$data.user.c_password=this.$data.user.password
        var n =this.$data.user.name.split(' ')
        var name, lastname
        name = n[0]
        lastname= n[1]
        API.register(name, lastname, this.$data.user.email, this.$data.user.password, this.$data.user.c_password, DniTrim.string(this.$data.user.dni), this.$data.user.phone, this.$data.user.type).then( (res: any) => {
          if (res.status != 200) {
            this.$data.btn.isOk = false
            if (res.message) {
              EventBus.$emit('snack-error', res.message)
              this.$data.errorMessage.push(res.message)
            }
          } else {
            this.$data.btn.isOk = true
            this.$data.dialog = true
            setTimeout(() => {
              API.login(this.$data.user.dni, this.$data.user.password).then((data: any) => {
                this.$store.commit('setUser', data.usuario)
                this.$store.commit('setToken', data.access_token)
                this.$store.commit('setExpireTime', data.expires_at)
                this.$store.commit("setMenu", data.usuario.user_tipo_id);
                if(this.$data.user.type == 3){
                  this.$router.push("/panel-facto/estadisticas")
                }else{
                  this.$router.push({
                    path: '/panel/estadisticas'
                  })
                }
              })
            }, Configuration.Redirection.timeout)
          }
        }).catch( (error: any) => {
          this.$data.btn.isOk = false
          console.log(error.message)
          this.$data.errorMessage.push("Intente nuevamente")
          EventBus.$emit('snack-error', error.message)

        })
      }
    },
    doRecaptcha() {
      // @ts-ignore
      this.$refs.recaptchaRegister.execute()
    },
    verificarRegex(regex:string, password:string):boolean{
        var pattern = new RegExp(regex);
        return pattern.test(password);
    },
    validatePassword(password) {
      let contador = 0;
      let isValid = false;
      if(this.$data.rules.password.length > 0){
        //@ts-ignore
        this.$data.rules.password.forEach((rule,i)=>{
          //@ts-ignore
          if(this.verificarRegex(rule.regex, password)){
                  contador++;
                  if(contador == this.$data.rules.password.length){
                      isValid = true;
                  }
                  else{
                      isValid = false;
                  }
              }
          })
      }
      else {
        isValid = true;
      }
        return isValid;
    },
  },
  components: {
    DniField,
    ReusableButton,
    ReCaptcha,
  },
})
export default class Register extends Vue {}
